<template>
  <div>
    <transition name="fade">
      <CCard v-if="show" color="">
        <CCardHeader>
          Add Student
          <div class="card-header-actions">
            <CLink
              class="card-header-action btn-minimize"
              @click="isCollapsed = !isCollapsed"
            >
              <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" />
            </CLink>
            <CLink
              href="#"
              class="card-header-action btn-close"
              v-on:click="show = false"
            >
              <CIcon name="cil-x" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="isCollapsed" :duration="400">
          <CCardBody>
            <form>
              <div class="form-group row">
                <label
                  for="teacherNameFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Student Name</label
                >
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="Student Name"
                   v-model="form.studentName">
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="classFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Student Address</label
                >
                <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder="Student Address"
                   v-model="form.studentAdress">
                </div>
              </div>

              <div class="form-group row">
                <label
                  for="subjectFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >DOB</label
                >
                <div class="col-sm-8">
                  <input type="date" class="form-control"
                   v-model="form.studentDOB">
                </div>
              </div>

              <div class="form-group row">
                <label
                  for="divisionFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >School</label
                >
                <div class="col-sm-8">
                  <select class="form-control" v-model="form.school_id">
                    <option value disabled hidden>Select School</option>
                    <option
                        v-for="(y,i) in school_list"
                        v-bind:key="i"
                        :value="y.school_id"
                    >{{y.school_name}}</option>
                  </select>
                </div>
              </div>
              <!-- end form-group -->

              <div class="form-group row">
                <label
                  for="yearFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Parent Name</label
                >
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="Parent Name"
                   v-model="form.parentName">
                </div>
              </div>
               <div class="form-group row">
                <label
                  for="yearFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Parent Email</label
                >
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="Parent Email"
                   v-model="form.parentEmail">
                </div>
              </div>
               <div class="form-group row">
                <label
                  for="yearFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Parent Contact</label
                >
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="Parent Contact"
                   v-model="form.parentContact">
                </div>
              </div>
              <!-- end form-group -->
              <div class="form-group row">
                <div class="col-sm-8 offset-sm-4">
                  <button type="submit" class="btn btn-primary mr-3 text-white" @click="add_student">
                    Submit
                  </button>
                  <button type="submit" class="btn btn-light">
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </CCardBody>
        </CCollapse>
      </CCard>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import apis from '@/apis';
const base_url=process.env.VUE_APP_BASE_URL;


export default {
  name: "AddTeacher",
  components: {},
  data: function() {
    return {
      show: true,
      isCollapsed: true,
      teacher_list:[],
      school_list:[],
      teacher:"",
      class_id:"",
      division:"",
      year:"",
      subject:"",
       form: {
        studentName:'',
        studentAdress:'',
        studentDOB:'',
        parentName:'',
        parentContact:'',
        parentEmail:'',
       
      },

    };
  },
  methods:{
    add_student(){
      var data = {
       // 'division_id':1,
          'student_name': this.form.studentName,
          'student_address': this.form.studentAdress,
          'student_dob': this.form.studentDOB,
          'school_id': this.form.school_id,
          'parent_name': this.form.parentName,
          'parent_email_id': this.form.parentEmail,
          'parent_contact_number': this.form.parentContact,
      };
      console.log(data);
      var promise = apis.add_Students(data).then( response => {
          alert('Data Saved Successfully!!');
      }).catch( error => {

      });
    },
   
   
    get_school(){
       var promise = apis.list_Schools().then( response => {
          this.school_list = response.data;
      }).catch( errror => {

      })
    },
  },
  created(){
    this.get_school();
  }
};
</script>
